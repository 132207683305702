
$gutter: 1rem;
@import "./assets/layout/layout.scss";
@import './assets/themes/mi-theme/theme.scss';
@import "../node_modules/primeflex/primeflex.css";
@import '../node_modules/primeicons/primeicons.css';


@import 'shared/components/header.component';
@import 'shared/components/page-header.component';
@import 'shared/directives/search-location.directive';
// @import 'shared/components/mi-grid.component';
@import 'app/pages/sign-in.component';
@import '../src/assets/themes/mi-theme/common_primeflex.scss';

.p-hidden-accessible {
    display: none;
}
