// Layout
.left-20 {
    left: 20px;
}

.left-55 {
    left: 55%;
}

.left-6 {
  left: 6px;
}

.left-62 {
    left: 62%;
}

.left-79 {
    left: 79%;
}

.right-7 {
  right: 7px;
}

.right-20 {
  right: 20px;
}

.right-25 {
    right: 25px;
}

.right-30 {
  right: 30px;
}

.right-10 {
  right: 10px;
}

.bottom-41 {
  bottom: 41px;
}
.bottom-m-15 {
  bottom: -15px;
}

.bottom-m-28 {
  bottom: -28px;
}

.left-81 {
  left: 81px;
}
.bottom-18 {
  bottom: 18px;
}

.bottom-10 {
  bottom: 10px;
}

.top-28 {
  top: 28px;
}

.top-7 {
  top: 7px;
}

.top-5 {
  top: 5px;
}

.top-15 {
  top: 15px;
}

.top-30 {
  top: 30px;
}

.top-10 {
  top: 10px;
}

.left-30 {
  left: 30px;
}

.left-325 {
  left: 325px;
}

.right-40 {
  right: 40px;
}

.right-205 {
  right: 205px;
}

.right-115 {
  right: 115px;
}

.left-36{
  left: 36px;
}

.bottom-20 {
  bottom: 20px;
}

//Size
.width-110 {
    width: 110%;
}

.height-45 {
  height: 45px;
}

.width-45 {
  width: 45px;
}

.height-15{
  height: 15px;
}
.width-15{
  width: 15px;
}
.height-26 {
  height: 26px;
}

.width-26 {
  width: 26px;
}

.height-37{
  height: 37.11px;
}

.width-37{
  width: 37.11px;
}

.height-90 {
  height: 90%;
}

.height-91 {
  height: 91%;
}

.width-96 {
  width: 96%;
}

.height-53 {
  height: 53px;
}

.width-53 {
  width: 53px;
}

.width-40 {
  width: 40%;
}

.width-200 {
  width: 200px;
}

.max-w-40 {
  max-width: 40%;
}

.height-calc {
  height: calc(100vh - 200px);
}

.height-85 {
  height: 85%;
}

// color
.border-offwhite {
  border-color: #F5F6FA;
}

.bg-cyan-msg {
  background-color: #6C9BC7;
}

// margin & padding
.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.padding-10 {
  padding: 10px;
}

// font
.font-20 {
  font-size: 20px;
}

.font-10 {
  font-size: 10px;
}

//positions 
.float-r {
  float: right;
}

.float-l {
  float: left;
}

/* scrollbar */
::-webkit-scrollbar {
    width: 0px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }
  


  .loader-page-center-overlay {
      position: absolute;
      background: transparent;
      opacity: .4;
      width: 100%;
      height: 100%;

      .spin-div {
        position: absolute;
        top: 48%;
        left: 48%;
      }
  }
