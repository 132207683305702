@import "./../../../../../assets/themes/mi-theme/variables";

$bodyBgColor: #ddd;
$mainContentBgColor: #eee;
$topBarBgColor: #009688;
$topBarItemHoverBgColor: #00877b;
$linkColor: #9158d9;
$topBarTextColor: #fff;
$sidebarBgColor: #fff;
$sidebarMenuTextColor: #000;
$sideBarHoverBgColor: #1c1f22;
$sidebarMenuHoverTextColor: #fff;
$signInBgColor: #009688;
$headerFontSize: 24px;
$buttonDefaultTextColor: #fff;
$buttonDefaultBgColor: #9158d9;
$pageHeaderBgColor: #ddd;
$panelDivBgColor: #fff;
$darkGrayColor: #262c2d;
$linkBlueColor: #1e90ff;
$primary: #07889b;
$secondary: #66b9bf;
$dragging: #eeaa7b;
$text: #fff;
$dt-background: #e0e1d7;
$bgLightGrayColor: #e0e0e0;
$listBgLightGray: #f5f5f5;

// Forms

$formFieldTopBottomMargin: 6px;
$formFieldLeftRightMargin: 0;
$inputFieldsBgColor: #fff;
$inputFieldBorderColor: #ddd;
$inputTextHeight: 30px;

// Pring NG

$fontFamily: "Lato", sans-serif;
$fontSize: 14px;
$borderRadius: 2px;
$disabledOpacity: 0.35;
$fontColor: #000;

// Header
$headerBorderWidth: 1px;
$headerBorderColor: #fff;
$headerBgColor: #fff;
$headerTextColor: #444;
$headerFontWeight: bold;
$headerIconTextColor: #444;

// Content
$contentBorderWidth: 1px;
$contentBorderColor: #ddd;
$contentBgColor: #fff;
$contentTextColor: #444;

// Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #ddd;
$stateDefaultBgColor: #f6f6f6;
$stateDefaultTextColor: #0073ea;

// Active State
$stateActiveBorderColor: #ddd;
$stateActiveBgColor: #fff;
$stateActiveTextColor: #9158d9;

// Highlight State
$stateHighlightBorderColor: #9158d9;
$stateHighlightBgColor: #9158d9;
$stateHighlightTextColor: #fff;

// Focus State
$stateFocusBorderColor: #0073ea;
$stateFocusBgColor: #0073ea;
$stateFocusTextColor: #fff;

// Error State
$stateErrorBorderColor: #ddd;
$stateErrorBgColor: #fff;
$stateErrorTextColor: #ff0084;

// Hover State
$stateHoverBorderColor: #0073ea;
$stateHoverBgColor: #0073ea;
$stateHoverTextColor: #fff;

// Forms
$inputBgColor: #fff;
$inputTextColor: #444;
$invalidInputBorderColor: #ff0084;

// Glow messages
$growlMessageErrorColor: #f44336;
$growlMessageSuccessColor: #4caf50;

// Grid
$gridHeaderTextColor: #fff;
$gridHeaderBgColor: #009688;
$gridHeaderBorderColor: #009688;
$gridBtnColorDanger: #d9534f;
$gridBtnColorDangerFocus: #c9302c;
$gridBtnColorSuccess: #5cb85c;
$gridBtnColorSuccessFocus: #449d44;

$loaderBgColor: transparent;

// Colors
$white: #fff;
$defaultThemeColor: #fff;
$secondaryThemeColor: #3d4246;
$inputGroupTextColor: #000;

$btnBgColor1: #95ad6f;
$btnBgColor2: #315e08;
$btnBgColor3: #003;
$btnBgColor: #315e08;
$themeColor: #360;

$bodyBgColor: #f6f6f6;

$mainContentBgColor: #f6f6f6;
$topBarBgColor: #fff;
$topBarItemHoverBgColor: #f1f1f1;
$BodyTextColor: #454545;

$linkColor: #666161;
$topBarTextColor: #303030;

$sidebarBgColor: #fff;
$sidebarMenuTextColor: #000;
$sideBarHoverBgColor: #0c1224;
$sidebarMenuHoverTextColor: #fff;
$sidebarIconColor: #b0bec5;
$sidebarActiveColor: #f7941d;

$signInBgColor: #009688;
$headerFontSize: 24px;
$headerSplitBarColor: #cbd1dc;
$headerUserName: #101010;

$buttonDefaultTextColor: #fff;
$buttonDefaultBgColor: $defaultThemeColor;
$negativeButtonColor: $secondaryThemeColor;
$buttonHoverColor: #51575c;
$negativeButtonHoverColor: #24282a;

$pageHeaderBgColor: transparent;
$panelDivBgColor: #fff;
$buttonGrayColor: #e0e0e0;

// Forms

$formFieldTopBottomMargin: 8px;
$formFieldLeftRightMargin: 0;
$inputFieldsBgColor: #fff;
$inputFieldBorderColor: #e1e3e7;
$inputTextHeight: 30px;
$readOnlyFieldColor: #dedede;
$activeBoxTextColor: #636363;
$inputBorderColor: #e1e3e7;

// Pring NG

$fontFamily: "OpenSans", sans-serif;
$fontSize: 14px;
$borderRadius: 2px;
$disabledOpacity: 0.35;
$calendarIconColor: #3d3d3d;
$dropdownBgColor: #1a1a1a;
$dropdownBorderColor: #c5c0cb;
$selectButtonBorderColor: #eee;

// Header
$headerBorderWidth: 1px;
$headerBorderColor: #fff;
$headerBgColor: #fff;
$headerTextColor: #444;
$headerFontWeight: bold;
$headerIconTextColor: #444;

// Content
$contentBorderWidth: 1px;
$contentBorderColor: #ddd;
$contentBgColor: #fff;
$contentTextColor: #444;

// Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #ddd;
$stateDefaultBgColor: #f6f6f6;
$stateDefaultTextColor: #0073ea;

// Active State
$stateActiveBorderColor: #ddd;
$stateActiveBgColor: #fff;
$stateActiveTextColor: #fff;

// Highlight State
$stateHighlightBorderColor: #9158d9;
$stateHighlightBgColor: #9158d9;
$stateHighlightTextColor: #fff;

// Focus State
$stateFocusBorderColor: #0073ea;
$stateFocusBgColor: #0073ea;
$stateFocusTextColor: #fff;

// Error State
$stateErrorBorderColor: #ddd;
$stateErrorBgColor: #fff;
$stateErrorTextColor: #ff0084;

// Hover State
$stateHoverBorderColor: #0073ea;
$stateHoverBgColor: #0073ea;
$stateHoverTextColor: #fff;

// Forms
$inputBgColor: #fff;
$inputTextColor: #444;
$invalidInputBorderColor: #ff0084;

// Glow messages
$growlMessageErrorColor: #f44336;
$growlMessageSuccessColor: #4caf50;

// Grid
$gridHeaderTextColor: #1e1e1e;
$gridHeaderBgColor: #ededed;
$gridHeaderBorderColor: $topBarBgColor;
$gridBtnColorDanger: #d9534f;
$gridBtnColorDangerFocus: #c9302c;
$gridBtnColorSuccess: #5cb85c;
$gridBtnColorSuccessFocus: #449d44;
$loaderBgColor: transperent;
$actionButtonDeactivate: #1e1e1e;
$gridSelectedRowHover: #d9dbdd;
$gridFileHoverColor: #4caf50;
$borderRightColor: #ddd;

$color_log_cabin_approx: #222;
$color_boulder_40_approx: rgba(120, 120, 120, 0.4);
$color_fuscous_gray_40_approx: rgba(80, 80, 80, 0.4);
$color_shark_40_approx: rgba(40, 40, 40, 0.4);
$black_40: rgba(0, 0, 0, 0.4);
$color_japanese_laurel_approx: #006400;
$color_red_berry_approx: #8b0000;
$color_surf_crest_approx: #cec;
$color_quill_gray_approx: #d3d3d3;
$color_pumice_approx: #c7c7c7;
$color_danube_approx: #5b9bd5;
$color_silver_approx: #bebebe;
$color_black_haze_approx: #f6f6f6;
$color_gallery_approx: #f0f0f0;
$white_50: rgba(255, 255, 255, 0.5);
$black: #000;
$gray: #808080;

$gridOddRowColor: #fff;
$gridRowSelectedColor: #eeeaea;
$gridSelectorHighliterColor: #ededed;
$paginationTextColor: #3e3e3e;

// Checkbox
$checkboxBackgroundColor: #fff;
$checkboxBorderColor: #ededed;
$checkboxActiveBorderColor: #e1e3e7;
$checkboxCheckColor: $defaultThemeColor;

// Table
$tableHeaderText: rgba(30, 30, 30, 0.5);

// google map realate color
$mapPickup: #4da03a;
$mapDrop: #ec1d25;

$destinationBox: #e9e9ec;
$activeStatus: #325e07;
$inactiveStatus: #901e1e;
$endedStatus: #f76108;

$fontColor: #757575;
$tabActiveTextColor: $activeStatus;
$fontNormal: normal;
$gridIconColor: #7d7d7d;
$gridRowBgColor: #dedede;

.company-logo {
  background-color: $defaultThemeColor;
  padding: 16px;
  text-align: left;
  height: 60px;
  border-bottom: 1px solid #e0e0e0;

  img {
    height: 100%;
  }
}

.sidebar {
  height: 100vh;
  width: 85px;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  padding-top: 0px;
  white-space: nowrap;
  background-color: $sidebarBgColor;
  z-index: 999;
  box-shadow: 2px 2px 3px #ddd;
  border-top-right-radius: 20px;

  .sidebar-body {
    overflow-y: scroll;
    height: calc(100vh - 60px);
  }

  &.shrink-width {
    width: 89px;

    .p-menuitem-text {
      padding: 8px 8px 8px 0px;
      font-size: 14px;
      font-weight: 500;
      transition: 0.5s;
      display: none;
    }

    .shrink-width-logo {
      font-size: 18px;
      font-weight: 700px;
    }

    .full-width-logo {
      display: none;
    }

    .p-panelmenu-header > a .p-panelmenu-icon {
      display: none;
    }

    .p-panelmenu-header {
      a {
        margin: 0;
      }
    }
  }

  &.full-width {
    width: 250px;

    .p-menuitem-text {
      padding: 8px 8px 8px 0px;
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }

    .shrink-width-logo {
      display: none;
    }

    .company-logo {
      // background-color: ##FF;
    }

    .p-panelmenu-header {
      a {
        margin: 0 20px;
      }
    }
  }

  .p-widget-content {
    background-color: $sidebarBgColor;
    border: 0 none;

    .p-menuitem-link {
      border-left: 3px solid $sidebarBgColor;
      color: $sidebarMenuTextColor;
      height: 40px;
      padding: 8px 4px 3px;

      &:hover {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        color: $sidebarMenuHoverTextColor;
      }

      &:active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 0 solid $defaultThemeColor;
        color: $sidebarMenuHoverTextColor;
        padding: 8px 4px 3px;
      }

      .p-menuitem-text {
        font-size: 12px;
      }
    }

    .p-menuitem {
      &:not(.p-state-active) {
        &:hover {
          background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          color: $sidebarMenuHoverTextColor;
        }

        &:active {
          background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          border-left: 3px solid $defaultThemeColor;
          color: $sidebarMenuHoverTextColor;
        }
      }

      .ui-state-active {
        background: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 3px solid $defaultThemeColor;

        .ui-menuitem-text {
          color: $sidebarMenuHoverTextColor;
          font-size: 12px;
        }
      }
    }
  }

  .p-panelmenu {
    .fa {
      color: $sidebarIconColor;
      font-size: 20px;
      margin-right: 12px;
    }

    .p-panelmenu-header {
      .p-menuitem-icon {
        padding: 14px 12px;
        margin: 12px;
      }

      .p-menuitem-link-active {
        background-color: #f5f5f5;
        border-radius: 6px;
      }

      &:not(.p-highlight):not(.p-disabled) > a:hover {
        border: none;
        background-color: #f5f5f5;
        border-radius: 6px;
      }

      a {
        border-left: 9px solid transparent;
        color: $sidebarMenuTextColor;
        margin: 0 18px;
        background: $sidebarBgColor;
        border: none;
        &:first-child {
          // margin-top: 10px;
        }
      }

      &:active {
        background-color: transparent;
        border-color: #fff;
        border-radius: 0;

        a {
          color: $sidebarMenuTextColor;
        }
      }

      .p-state-active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 9px solid $sidebarActiveColor;
        color: $sidebarActiveColor;
      }

      .p-panelmenu-headerlink-hasicon {
        &.ui-state-active {
          background: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          box-shadow: inset 3 0 0 0 rgba(236, 29, 37, 1);
          color: #fff;
        }

        &:hover {
          background-color: $sideBarHoverBgColor;
          border-top: 0;
          box-shadow: inset 3px 0 0 0 rgba(236, 29, 37, 1);
        }
      }
    }
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action { 
    padding: 0;
  }

  a {
    color: $sidebarMenuHoverTextColor;
  }

  .p-state-default {
    background: $sidebarBgColor;
    border: 1px solid $sidebarBgColor;
    color: $sidebarMenuTextColor;
  }

  .fa-caret-right,
  .fa-caret-down {
    &::before {
      content: "";
    }
  }

  .p-panelmenu-icon {
    &.fa {
      // display: none;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 1600px) {
    .left-panel {
      width: 15%;
    }

    .right-panel {
      width: 85%;
    }
  }

  @media screen and (max-width: 1600px) and (min-width: 1200px) {
    .left-panel {
      width: 20%;
    }

    .right-panel {
      width: 80%;
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 1000px) {
    .left-panel {
      width: 22%;
    }

    .right-panel {
      width: 78%;
    }
  }

  .sidebar-panel-menu {
    .p-panelmenu-header-link {
      &:hover {
        .mi-lg {
          background: $primaryColor;
        }

        .p-menuitem-text {
          color: $primaryColor;
        }
      }
    }

    .p-panelmenu .p-panelmenu-content {
      border: 0;
    }

    .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
      margin-right: 0rem;
      position: absolute;
      right: 16px;
    }

    .p-panelmenu .p-menuitem-link {
      left: 48px;
      position: relative;
    }

    .p-panelmenu .p-submenu-list {
      padding-top: 10px;

      li.p-menuitem {
        padding: 10px 10px 10px 0;
        margin: 0 18px;
        margin-bottom: 5px;
        &:hover {
          background: #f5f5f5;
          border-radius: 6px;
        }
      }
    }
  }
}

.p-panelmenu
  .p-panelmenu-header
  .p-panelmenu-header-content
  .p-panelmenu-header-action {
  color: #495057;
  /* padding: 1rem; */
  font-weight: 600;
}

.sidebar {
  .p-panelmenu {
    .p-panelmenu-header a {
      border-left: 9px solid transparent;
      color: #000;
      /* margin: 0 18px; */
      background: #fff;
      border: none;
    }
  }
}
.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
  color: #495057;
  /* padding: 1rem; */
  font-weight: 600;
}

.sidebar .p-panelmenu .p-panelmenu-header a {
  border-left: 9px solid transparent;
  color: #000;
  /* margin: 0 18px; */
  background: #FFF;
  border: none;
}


.p-menubar {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.sidebar .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content{
 transition: 0.7s;
.p-panelmenu-header-action {
  padding: 0;
  margin-left: -2px;
  margin-right: -2px;
}
}

.sidebar .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content :hover {
 background-color: rgb(249, 246, 246);
 color: $linkBlueColor;
 padding-left: 7px;
 transition: 0.4s;
}

.sidebar .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
  padding: 0;
  margin-left: 12px;
  margin-right: -2px;
}

.p-menubar {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: white;
}