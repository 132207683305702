// <i icon="{{miIcons.COLUMNS}} block"> </i> inline item provide block
//     <div class="{{item.value}} wh-24" ></div> block item provide width and height

.mi {
    width: 16px;
    height: 16px;
    background-size: cover;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: $iconPrimaryBG;
    &:hover {
        background-color: $primaryColor;
       }
}

.mi-lg {
    height: 100%;
    background-size: cover;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: $iconPrimaryBG;
    &:hover {
     background-color: $primaryColor;
    }

    &.wh-24 {
        width: 24px;
        height: 24px;
    }

    &.wh-10 {
        width: 10px;
        height: 10px;
    }

    &.wh-12 {
        width: 12px;
        height: 12px;
    }

    &.wh-13 {
        width: 13px;
        height: 13px;
    }
    
    &.wh-14 {
        width: 14px;
        height: 14px;
    }

    &.wh-15 {
        width: 15px;
        height: 15px;
    }

    &.wh-16 {
        width: 16px;
        height: 16px;
    }

    &.wh-20 {
        width: 20px;
        height: 20px;
    }
       
    &.wh-80 {
        width: 80px;
        height: 81px;
    }
}

.mi-xl {
    width: 32px;
    height: 32px;
    background-size: cover;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: $iconPrimaryBG;
    &:hover {
        background-color: $primaryColor;
       }
}

.mi-home {
    // mask-image: url("#{$base}/nav/Home.svg");

    &:hover {
        // mask-image: url("#{$base}/nav/Home.svg");
    }
}